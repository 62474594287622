import {
   immediate_action,
} from "../../js/path";

export default {
   data() {
      return {
         pageNo: null,
         immediate_action_id: null,
         fields: [{
               key: "id",
               label: "ID",
               sortable: true,
            },
            {
               key: "action"
            },
            {
               key: "action_id",
               label: "Action ID",
            },
            {
               key: "value",
               label: 'Description'
            },
            {
               key: 'bg_color',
               label: 'BG Color'
            },
            {
               key: 'edit'
            },
            {
               key: 'delete'
            }
         ],
         params: "",
         filter: null,
         filterOn: [],
         tableData: [],
         sortBy: "id",
         sortDesc: true,
         key: 0,
         currentPage: 1,
         form: {
            action: '',
            action_id: '',
            bg_color: '',
            value: '',
            status: 0
         },
         image_name: '',
         image_name_url: '',
         edit: {
            image_name_url: null
         }
      };
   },
   methods: {
      search(event) {
         if (this.filter.length > 1) {
            if (event.keyCode == 13) {
               this.fetchData('search');
            }
         } else if (this.filter.length == 0) this.fetchData('search');
      },
      searchFor(){
         if (this.filter.length > 0) {
            this.fetchData('search');
         } else {
            this.fetchData('search');
         }
      },
      filterPage() {
         if (this.pageNo.length > 0) {
            this.params = `&page=${this.pageNo}`;
            this.fetchData(this.activeTab);
            this.currentPage = this.pageNo;
         }
      },
      onFiltered(filteredItems) {
         this.totalRows = filteredItems.length;
         this.currentPage = 1;
      },
      readFile(e, txt) {
         if (txt == 'image_name') {
            this.image_name = e.target.files[0];
            if (this.image_name.size > 2 * 1000 * 1000) {
               this.$store.commit('toast/updateStatus', {
                  status: true,
                  icon: 'error',
                  title: 'Uploaded File is more than 2MB'
               });
               this.$ref.image_name.reset();
               this.image_name = '';
               this.edit.image_name_url = '';
               this.image_name_url = '';
               return false;
            } else {
               this.image_name_url = URL.createObjectURL(this.image_name);
               this.edit.image_name_url = '';
            }
         }
      },
      async fetchData(pagination = null) {
         this.$store.commit("loader/updateStatus", true);
         try {
            let url = immediate_action.fetchImmediateAction;
            if (pagination == "search") {
               url = immediate_action.fetchImmediateAction + "?search=" + this.filter;
            } else if (pagination) {
               url = url + "?page=" + pagination;
            }
            const data = await this.getRequest(url);
            if (data.status) {
               this.tableData = data.response;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async fetchImmediateAction(id) {
         this.$store.commit('toast/updateStatus', true);
         try {
            let url = immediate_action.fetchImmediateAction + '/' + id;
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response;
               if (responseData.action != "" && responseData.action != null) {
                  this.form.action = responseData.action;
               }
               if (responseData.action_id != "" && responseData.action_id != null) {
                  this.form.action_id = responseData.action_id;
               }
               if (responseData.value != "" && responseData.value != null) {
                  this.form.value = responseData.value;
               }
               if (responseData.bg_color != "" && responseData.bg_color != null) {
                  this.form.bg_color = responseData.bg_color;
               }
               if (responseData.status) {
                  this.form.status = true;
               }
               this.immediate_action_id = responseData.id;
               this.edit.image_name_url = responseData.image_name;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!",
            });
         }
      },
      async deleteImmediateAction(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = immediate_action.fetchImmediateAction + "/" + id;
            const data = await this.postRequest(url, {
               _method: "DELETE",
            });
            if (data.status) {
               this.tableData.data.splice(index, 1);
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
         try {
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid) {
               this.$store.commit('toast/updateStatus', {
                  status: true,
                  icon: 'error',
                  title: 'Please Fill The Required Fields'
               });
               return false;
            }
            this.$store.commit("loader/updateStatus", true);
            let url = immediate_action.fetchImmediateAction;
            if (this.$route.name == "edit-immediate-action") {
               url = immediate_action.fetchImmediateAction + "/" + this.immediate_action_id;
            }
            let dataAppend = new FormData();

            if (this.image_name) {
               dataAppend.append("image_name", this.image_name);
            }

            if (this.form.status) {
               dataAppend.append("status", 1);
            } else {
               dataAppend.append("status", 0);
            }

            for (var key in this.form) {
               if (key != 'status') {
                  dataAppend.append(key, this.form[key]);
               }
            }

            if (this.$route.name == "edit-immediate-action") {
               dataAppend.append("_method", "put");
            }
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
               this.$router.push("/immediate-action");
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
   },
   watch: {
      currentPage: {
         handler: function (value) {
            if (value) {
               this.fetchData(value);
               this.pageNo = this.currentPage;
            }
         },
      },
      "form.status"(v) {
         if (v == 1) {
            this.form.status = true;
         } else {
            this.form.status = false;
         }
      },
   },
   created() {
      if (this.$route.name == 'add-immediate-action' || this.$route.name == 'edit-immediate-action') {
         if (this.$route.name == "edit-immediate-action") {
            this.fetchImmediateAction(this.$route.params.id);
         }
      } else {
         this.fetchData();
      }
   }
};